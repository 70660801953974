import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const AboutPage = () => (
    <Layout>
        <SEO title="About"/>

        <div className={"about page-header"}>
            <h2>Who Is Xapnik?</h2>
            <div>
                <p>Hi, I'm Kirsten Lambertsen. I've been creating products in tech for over 20 years. I built Xapnik to make it easier for my friends and I to keep track of each other on social media. It's important to me that I amplify my friends as much as possible, and that can be nearly impossible today with all the content and the algorithms deciding what we see.</p>
                <p>If you need support or have a suggestion, I'll be person who sees it and will respond to you right away because I care about your experience. I take pride in my reputation in the tech community, which means I take pride in taking good care of my users and customers.</p>
            </div>
        </div>

        
    </Layout>
)

export default AboutPage
